<template>
  <div class="page fos-page" :class="{ loaded: isLoaded }">
    <div class="half half-reverse content fos-content">
      <div class="half__pic" style="background-image: url(/img/fos/bg.png)" />
      <router-view />
    </div>
  </div>
</template>

<script>
import { loader } from '@/mixins/loader';

export default {
  name: 'FOS',
  mixins: [loader],
  data() {
    return {
      pageData: { page: 'FOS', isHeaderHidden: true },
    };
  },
  methods: {
    onActivated() {
      this.pageLoaded();
    },
  },
  activated() {
    this.onActivated();
  },
};
</script>

<style lang="less">
.fos-content {
  font-size: 2vh;
  @media screen and (min-width: 768px) {
    font-size: 1.2vh;
  }
  @media screen and (min-width: 1024px) {
    font-size: 1.4vh;
  }
  .h2 {
    font-size: 5vh;
    @media screen and (min-width: 1024px) {
      font-size: 5vh;
      margin-bottom: 1vh;
    }
  }
}
</style>
