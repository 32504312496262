export const loader = {
  data() {
    return {
      isLoaded: false,
      imagesLoaded: false,
      isSubPageLoaded: false,
    };
  },
  methods: {
    pageLoaded() {
      this.$store.commit('ui/loaderOFF');

      setTimeout(() => {
        this.isLoaded = true;
        this.isSubPageLoaded = true;
        this.$emit('loaded', this.pageData);
      }, 100);
    },
    loadImages(images, skipPageLoaded) {
      let i, j, objImage;
      const imagesCnt = images.length;
      let imagesLoaded = 0;

      this.$store.commit('ui/hideFooterMenu');
      this.$store.commit('ui/hideHeaderMenu');

      if (images.length) {
        for (i in images) {
          objImage = new Image();

          objImage.onload = () => {
            imagesLoaded++;

            if (imagesLoaded === imagesCnt) {
              this.$store.commit('ui/changeVoileTransitionEffects');
              this.imagesLoaded = true;
              if (!skipPageLoaded) {
                this.pageLoaded();
              }
            }
          };

          objImage.src = images[i];
        }
      } else {
        this.$store.commit('ui/changeVoileTransitionEffects');
        this.imagesLoaded = true;
        if (!skipPageLoaded) {
          this.pageLoaded();
        }
      }
    },
  },
};
